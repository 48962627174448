import React from 'react'

import { Location } from '@reach/router'
import { navigate } from 'gatsby'

import { FullPageLoader } from 'components/Loader'

const redirects = [
  { test: /beta\/reset_password\/*/, uri: '' },
  { test: /beta\/email\/*/, uri: 'app/settings/profile' },
  { test: /beta\/map/, uri: 'app' },
  { test: /beta\/dashboard/, uri: 'app' },
  { test: /beta\/invitation/, uri: '' },
  { test: /beta($|\/*)/, uri: '' }
]

export function redirectBetaPath (path) {
  const redirect = redirects.find(r => path.match(r.test))

  if (redirect) {
    path = path.replace(redirect.test, redirect.uri)
  }

  return path
}

class RedirectBeta extends React.Component {
  componentDidMount () {
    const { location } = this.props
    let path = location.pathname
    let search = location.search || ''
    let href = `${path}${search}`

    href = redirectBetaPath(href)

    navigate(href, { replace: true })
  }

  render () {
    return <FullPageLoader />
  }
}

export default () => {
  return (
    <Location>
      {({ location }) => {
        return <RedirectBeta location={location} />
      }}
    </Location>
  )
}
